export enum OptionsModalEnum {
  Logout = "Logout",
  ConfirmPinRescue = "ConfirmPinRescue",
}

export enum PaleteEnum {
  bluePrimary = "#17408B",
}

export enum ModalMessageKeyEnum {
  datePinValidate = "datePinValidate",
}

export enum ResponseMessagesEnum {
  SuccessAuth = "Login realizado com sucesso!",
  SuccessPin = "Pin resgatado com sucesso!",
  SuccessCreateUser = "Usuário criado com sucesso!",
  SuccessForgotPassword = "Senha atualizada com sucesso!",
  SuccessCode = "Código validado com sucesso!",
  SuccessSendCode = "Código enviado!",
  ErrorAuth = "Usuário ou senha inválidos!",
  ErrorPin = "Pin inválido!",
  ErrorCreateUser = "Usuário inválido ou já existente!",
  ErrorForgotPassword = "Ops, houve um problema ao atualizar sua senha. Por favor, tente novamente mais tarde",
  ErrorCode = "Código inválido!",
  ErrorSendCode = "Falha ao enviar o código!",
}

export enum AssinaturaEpaySituacaoEnum {
  NenhumaAssinatura = 0,
  AssinaturaAtiva = 1,
  AssinaturaAtivaSemPin = 2,
}

export enum TipoPinEnum {
  Epay = 1,
  Bhn = 2,
}
